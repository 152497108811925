/* You can add global styles to this file, and also import other style files */

body {
    background-color: #2e2e2e;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

body.modal-open {
    overflow: hidden !important;
}
